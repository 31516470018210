import * as React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'

const AboutPage = () => {
    return (
        <Layout pageTitle="Adams Wilson Bio">
            <div className='px-10'>

<h2 className='text-2xl mb-6 mt-10 font-bold'>Biography</h2>

<p>Adams Wilson was started by me, Adams Eberwein, in 2006, when I began playing solo acoustic gigs in the Salem County, New Jersey area. Venues had trouble getting my last name right on their sandwich boards and marquees, so I swapped my last name with "Wilson," one of my middle names that also happened to sound like a last name.</p>

<p>In 2007, I released a rough sketchbook of original songs that I made entirely at home, called Irrelevant Truth. A couple of the songs got played on WSTW's "Hometown Heroes" program, and some other internet channels.</p>

<div className='bg-gray-200 p-4 max-w-lg mx-auto justify-center my-10'>
    <img src="/images/photos/sandwich_board.jpg" className='mx-auto justify-center' alt="adams wilson on the sandwich board outside a venue in New York City" />
    <p className='mx-auto text-center text-sm my-4 mx-4 italic text-gray-900'>Even after I changed the name to "Adams Wilson," venues still struggled with the correct spelling.</p>
</div>

<p>In 2008, I pulled together some musician friends and went to Miner Street Studios in Philadelphia, PA. We worked with producer Brian McTear (Burning Brides, Matt Pond PA) to put together a more polished production of a new batch of songs, which eventually came to be known as "<Link to="/albums/my-best-guess">My Best Guess</Link>."</p>

<p>The experience of collaborating with other musicians gave me a new energy, and while wrapping up the album, I put together a band. We gigged at local wineries, bars, backyard BBQs, churches... anywhere that would let us play. We tried to come up with a new name for the band, and while we were known as "Synistereo" for one whole gig, we eventually settled back on "Adams Wilson" since we had already a small following under that name.</p>

<div className='bg-gray-200 p-4 max-w-2xl mx-auto justify-center my-10'>
    <img src="/images/photos/winery.jpg" className='mx-auto justify-center' alt="adams wilson at Auburn Road Winery in Pilesgrove, NJ" />
    <p className='mx-auto text-center text-sm my-4 mx-4 italic text-gray-900'>Adams Wilson at Auburn Road Winery in Pilesgrove, NJ.</p>
</div>

<p>It was my original intention that we would put out a new album every year, however, gigging, rehearsals, and keeping the band fully-staffed proved to be a major obstacle to that goal. I wrote and recorded enough material for 2 albums on my own, and the band recorded a full-length album at a friend's studio, but we were never able to settle on a cohesive sound. All the material we came up with was quietly put on a shelf.</p>

<p>We spent much of the time from 2009-2011 playing bigger shows throughout South Jersey, Philadelphia, Delaware, and New York City. In 2011, we knew it had simply been too long since we'd put anything out, so we locked ourselves in our rehearsal space and recorded an album by ourselves, called "<Link to="/albums/everything-we-know">Everything We Know</Link>." It was rough, but it was us, and it was on record.</p>

<div className='bg-gray-200 p-4 max-w-2xl mx-auto justify-center my-10'>
    <img src="/images/photos/2010.jpg" className='mx-auto justify-center' alt="Adams Wilson in 2010: Adams Eberwein, Jason Rugel, Matt Riley, and Darren Darling" />
    <p className='mx-auto text-center text-sm my-4 mx-4 italic text-gray-900'>Adams Wilson in 2010: Adams Eberwein, Jason Rugel, Matt Riley, and Darren Darling</p>
</div>

<p>A music industry veteran heard "Everything We Know" and said that the songwriting was strong, but suggested that we record an EP with an experienced producer so that we could try and hone our sound and perhaps get some attention from bigger venues or headliner acts. That led us to Robby Takac's (bassist, vocalist, and founding member of the Goo Goo Dolls) "GCR Audio" in Buffalo, NY. We worked out a 5-song EP with Robby titled "<Link to="/albums/lies-worth-telling">Lies Worth Telling</Link>."</p>

<p>For one of the songs, "<Link to="/albums/prairie-fires#Golden">Everything is Golden</Link>," Robby suggested a pedal steel part, and brought in an incredible musician to do a guest spot. Matt and I (the two primary songwriters at the time) felt that "<Link to="/albums/prairie-fires#Golden">Golden</Link>" was the best song we'd ever written, and its Americana vibe was exactly what we had been chasing for so many years. It inspired a whole new album of material in the same vein, and in 2013, we returned to GCR to record a full-length album called "<Link to="/albums/prairie-fires">Prairie Fires</Link>."</p>

<div className='bg-gray-200 p-4 max-w-2xl mx-auto justify-center my-10'>
    <img src="/images/photos/buffalo_1.jpg" className='mx-auto justify-center' alt="adams wilson recording with Robby Takac and Jim Whitford at GCR Audio in Buffalo, NY" />
    <p className='mx-auto text-center text-sm my-4 mx-4 italic text-gray-900'>Producer Robby Takac oversees recording of pedal steel player Jim Whitford for "Everything is Golden" at GCR Audio in Buffalo, NY in 2012.</p>
</div>

<p>"<Link to="/albums/prairie-fires">Prairie Fires</Link>" helped to put us on the radar of John Eddie, a veteran Americana performer who's written songs for Kid Rock, Blake Shelton, and others. We were fortunate enough to open for him at venues that would normally be out of our reach, like the Wonderbar in Asbury Park, NJ, and Ardmore Music Hall in Ardmore, PA.</p>

<div className='bg-gray-200 p-4 max-w-2xl mx-auto justify-center my-10'>
    <img src="/images/photos/john_eddie.jpg" className='mx-auto justify-center' alt="adams wilson with john eddie at wonderbar in asbury park, nj" />
    <p className='mx-auto text-center text-sm my-4 mx-4 italic text-gray-900'>Adams Wilson opening for John Eddie at Wonderbar in Asbury Park, NJ.</p>
</div>

<p>After repeating the cycle of gig/write/record/release/gig so many times, I wanted to try a new way of connecting with our audience. That desire led to 2015-2016's "<Link to="/albums/the-postcard-project">Postcard Project</Link>." Every month, we wrote and recorded a brand new song, and shot a music video to go with it. Each song was posted to our <a href="https://www.youtube.com/user/smadatwo">YouTube channel</a>. It was a unique and challenging experiment that added 12 new songs to our catalogue.</p>

<div className='bg-gray-200 p-4 max-w-2xl mx-auto justify-center my-10'>
    <img src="/images/photos/adwil_postcard_album_cover_20151016.jpg" className='mx-auto justify-center' alt="adams wilson at volume cafe in turnersville, nj" />
    <p className='mx-auto text-center text-sm my-4 mx-4 italic text-gray-900'>Adams Wilson's "The Postcard Project"</p>
</div>

<p>By the end of the "<Link to="/albums/the-postcard-project">Postcard Project</Link>," I came to realize that my energy was, and always had been, fueled by writing and recording. Gigging, and all the work that goes into it, was fun in its own way, but it was a major diversion from the creative aspects I loved so much. I therefore made the difficult decision to retire from live performance, and focus my energy on writing and recording. The rest of the band, Matt, Brian, Annette, and Bob, split off to form a new group called "Lights of the North" which has since disbanded. Adams Wilson sold out its final show on January 14, 2017 at Volume Cafe in Turnersville, NJ.</p>

<div className='bg-gray-200 p-4 max-w-2xl mx-auto justify-center my-10'>
    <img src="/images/photos/final_show_20170114.jpg" className='mx-auto justify-center' alt="adams wilson at volume cafe in turnersville, nj" />
    <p className='mx-auto text-center text-sm my-4 mx-4 italic text-gray-900'>Matt Riley, Annette Williams, Brian Scorben, Adams Eberwein, Bob Foulks at the last Adams Wilson show. Volume Cafe, Turnersville, NJ, January 14, 2017.</p>
</div>

<p>In the spring of 2017, I released my second solo record, <Link to="/albums/hard-summer">"Hard Summer."</Link> It includes a couple of songs from the "dark ages" when we recorded often, but released nothing, and a batch of entirely new songs, written and recorded by me after the "Postcards Project."</p>

<p>In 2018, a movie called "The Keeping Hours" with Lee Pace featured Adams Wilson's "If You Wanna Run."</p>

<p>During 2018, I wrote a new set of songs with longtime friend Dan Illi who had previously worked with me on some of the early "Prairie Fires" material and my side project "24 Hour Blues Cycle." We took the songs we came up with to former Adams Wilson drummer Ryan Buzby whose drum tracks gave the songs punch and personality. Katie Skwirut (another "Blues Cycle" alum) then stepped in to provide backing vocals and harmonies. The resulting collection of songs became "<Link to="/albums/under-the-mountain">Under the Mountain</Link>," and was released in early January, 2019.</p>

<div className='bg-gray-200 p-4 max-w-2xl mx-auto justify-center my-10'>
    <img src="/images/photos/adams_wilson_band_20181211A.jpg" className='mx-auto justify-center' alt="Ryan Buzby, Adams Eberwein, Katie Skwirut, Dan Illi." />
    <p className='mx-auto text-center text-sm my-4 mx-4 italic text-gray-900'>The artists who contributed to "<Link to="/albums/under-the-mountain">Under the Mountain</Link>." From left to right: Ryan Buzby, Adams Eberwein, Katie Skwirut, Dan Illi.</p>
</div>

<p>Between the autumn of 2019 and the spring of 2020, Ryan Buzby, Dan Illi, and I worked on a new collection of songs that drew on Rock and Blues influences like Screaming Trees, Queens of the Stone Age, The Black Keys, Kenny Wayne Shepherd, Gary Clark, Jr., and Royal Blood. The result was a paradoxically darker and more energetic album. "<Link to="/albums/parasite-and-host">Parasite & Host</Link>" released on April 12, 2021.</p>

<p>In November of 2021, the song "<Link to="/albums/prairie-fires#Golden">Golden</Link>" from our <Link to="/albums/prairie-fires">Prairie Fires</Link> album was featured on the show "SEAL Team" on Paramount+.</p>
				</div>
				
			
        </Layout>
    )
}

export default AboutPage